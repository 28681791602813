export type LinkContext = {
  base?: string;
  locale: ContentLocale;
  products: string;
  exhibitors: string;
  producers: string;
  productLists: string;
  favorites: string;
  tastingList: string;
  home: string;
};

import encodeFilters from "./encodeFilters";
import { ContentLocale } from "./useLocale";

/**
 * Returns the functions to create a link to a particular entity.
 * This is in CommonJS because it is also used from gatsby-node.
 * @param {LinkContext | undefined} context
 * @returns
 */
export default function getLink(context) {
  return {
    /**
     * The base path of the site. This is used by packages (like CMS) that
     * don't have access to the router.
     */
    base: context?.base,
    /**
     * Returns the path to the exhibitors list.
     * @param {object} [filters] Any filters on products.
     * @returns
     */
    exhibitors: (filters) =>
      context &&
      `${path(context.base, context.exhibitors)}${encodeFilters(filters)}`,

    /**
     * Generates a link to an exhibitor.
     * @param {{ exhibitorId: string }} exhibitor
     * @returns
     */
    exhibitor: (exhibitor) => {
      if (!exhibitor) return undefined;
      return (
        context && path(context.base, context.exhibitors, exhibitor.exhibitorId)
      );
    },

    /**
     * Generates a link to the products list.
     * @param {object} [filters] Any filters on products.
     * @returns
     */
    products: (filters) =>
      context &&
      `${path(context.base, context.products)}${encodeFilters(filters)}`,

    /**
     * Generates a link to a product.
     * If a producerId is provided, it links to the producer's product route, otherwise it uses the exhibitor route (this behavior will probably change in the future).
     * @param {{ productId: string, exhibitorId?: string, producerId?: string }} product
     * @returns
     */
    product: (product) => {
      if (!context) return undefined;
      if (!product) return undefined;
      const { productId, exhibitorId, producerId } = product;
      if (exhibitorId) {
        return path(
          context.base,
          context.products,
          "by-exhibitor",
          exhibitorId,
          productId
        );
      }
      // TODO We don't support that route in the theme. Remove and move into a shadowed component.
      if (producerId) {
        return path(
          context.base,
          context.products,
          "by-producer",
          producerId,
          productId
        );
      }
      // We don't support direct links to products in this theme.
      // return path(context.base, context.products, productId);
    },

    /**
     * Generates a link to a producer.
     * @param {{ brandId: string }} producer
     * @returns
     */
    producer: (producer) => {
      if (!context) return undefined;
      if (!producer) return undefined;
      return path(context.base, context.producers, producer.brandId);
    },

    /**
     * Generates a link to the producers list.
     * @returns
     */
    producers: () => context && path(context.base, context.producers),

    /**
     * Generates a link to a product list.
     * @param {{ productListId: string }} productList
     * @returns
     */
    productList: (productList) => {
      if (!context) return undefined;
      if (!productList) return undefined;
      return path(
        context.base,
        context.productLists,
        productList.productListId
      );
    },

    /**
     * Generates a link to the home page.
     * @returns
     */
    home: () => context && path(context.base, context.home),
    /**
     * Generates a link to the home page "default" as managed from event settings.
     * @returns
     */
    default: () => context && path(context.base),

    // Static links:
    tastingNotes: () => context && path(context.base, "my", "tasting-notes"),
    /** Generates a link to the favorites list. */
    bookmarks: () => context && path(context.base, "my", "bookmarks"),
    /** Generates a link to the schedule. */
    schedule: () => context && path(context.base, "my", "schedule"),
    /** Generates a link to the schedule. */
    people: () => context && path(context.base, "people"),
    /** Generates a link to the tasting list. */
    tastingList: () => context && path(context.base, "my", "lists"),
    myProfile: () => context && path(context.base, "my", "profile"),
    mySchedule: () => context && path(context.base, "my", "schedule"),
    reports: () => context && path(context.base, "my", "reports"),
    favoritesCatalog: () => context && path(context.base, "my", "catalog"),
    exhibitorCatalogPage: (exhibitorId) =>
      context && path(context.base, "catalog", exhibitorId),
    catalog: () => context && path(context.base, "catalog"),

    __context__dontUseOrYouWillBurnInHell__: () => context,
  };
}

function path(...segments) {
  return `/${segments.filter(Boolean).join("/")}`.replace(/\/\/+/g, "/");
}
