import { redirect } from "react-router-dom";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import getCustomDomain from "./[locale]/_getCustomDomain";

export async function Loader() {
  try {
    const result = await graphQLClient.request(
      graphql(`
        query RedirectToDefaultLocale($customDomain: String!) {
          collection: _unstable_collectionByCustomDomain(
            experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
            locale: en
            customDomain: $customDomain
          ) {
            collectionId
            locales(format: RAW)
          }
        }
      `),
      { customDomain: getCustomDomain() }
    );
    const collection = result.collection;
    if (!collection)
      return redirect(
        `/error?errorCode=404&message=No collection found for this domain: ${getCustomDomain()}`
      );
    if (!collection.locales?.length)
      return redirect(
        `/error?errorCode=500&?message=No locales found for this collection: ${collection.collectionId}`
      );
    return redirect(`/${collection.locales[0]}`);
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error";
    return redirect(
      `/error?errorCode=500&message=Error loading collection: ${errorMessage}`
    );
  }
}
