import ExhibitorPage from "@bottlebooks/bottlebooks-site-base/src/components/ExhibitorPage/ExhibitorPage.next";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { z } from "zod";
import { graphql } from "~/gql";
import { getFragment } from "~/gql-local/fragment-masking";
import graphQLClient from "~/graphQLClient";
import getCollectionId from "../_getCollectionId";
import getCustomDomain from "../_getCustomDomain";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  exhibitorId: z.string(),
});

const fragment = graphql(/* GraphQL */ `
  fragment ExhibitorPage_Event on Event {
    eventId
    registrations(filter: { companyId: { eq: $companyId } }) {
      nodes {
        __typename
        registrationId
        ... on SingleRegistration {
          ...ExhibitorPage
        }
      }
    }
  }
`);

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader({ params }: LoaderFunctionArgs) {
  const { locale, exhibitorId } = paramsSchema.parse(params);
  const customDomain = getCustomDomain();
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ExhibitorPageByCustomDomain(
        $customDomain: String!
        $companyId: ID!
        $locale: ContentLocale
      ) {
        collection: _unstable_collectionByCustomDomain(
          experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
          customDomain: $customDomain
          locale: $locale
        ) {
          collectionId
          ...ExhibitorPage_Event
        }
      }
    `),
    {
      customDomain,
      companyId: exhibitorId,
      locale,
      // Required to access to cached data.
      collectionId: await getCollectionId({ customDomain: getCustomDomain() }),
    },
    { "bottlebooks-use-request-cache": "true" }
  );
  return result.collection;
}

export default function ProductPageTemplate() {
  const data = useLoaderData() as LoaderData;
  const event = getFragment(fragment, data);
  if (!event?.registrations.nodes) return <div>No registrations found.</div>;
  const registration = event?.registrations.nodes[0];
  return (
    <ExhibitorPage
      key={registration.registrationId}
      eventId={event.eventId}
      data={registration}
      // TODO Provide previous and next for paging through exhibitors.
      previous={undefined}
      next={undefined}
    />
  );
}
