/** @jsxImportSource theme-ui */
import {
  Box,
  Container,
  Flex,
  Grid,
  Stack,
} from "@bottlebooks/gatsby-theme-base/src";
import { defineMessage } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import groupBy from "lodash/groupBy";
import React from "react";
import useLink from "../../useLink";
import EmptyState from "../ExhibitorsPage/EmptyState";
import ActiveFiltersBar from "../Filters/ActiveFiltersBar";
import FilterField from "../Filters/FilterField";
import {
  filtersCleared,
  filterToggled,
  searchTermChanged,
  sortChanged,
} from "../Filters/filterReducer";
import Filters, { useFiltersDisplay } from "../Filters/Filters";
// eslint-disable-next-line import/no-unresolved
import useIndex from "../Filters/useIndex";
import useLocationSyncedState from "../Filters/useLocationSyncedState";
import ProductCard from "../ProductCard/ProductCard.next";
import GroupedProductsCard from "../ProductsList/GroupedProductsCard.next";
import ProductsList from "../ProductsList/ProductsList";
// import SEO from "../seo";
// import DownloadMyBookmarks from './DownloadMyBookmarks';
// eslint-disable-next-line import/no-unresolved
import { FragmentType, graphql, useFragment } from "~/gql";
import useProductFilters from "./productFilters.next";
import ProductsPageTitle from "./ProductsPageTitle";
import Sort from "./Sort";
import useSearch from "./useProductSearch.next";

const fragment = graphql(/* GraphQL */ `
  fragment ProductsPageBase on RegisteredProduct {
    ...ProductCard_RegisteredProduct
    # ...bb_GroupedProductsCard_RegisteredProduct
    registration {
      registrationId
      companyId
      stand {
        name
      }
      profile {
        sortName
        name
      }
    }
    product {
      productId
      shortName
      ...GroupedProductsCard
      ...Search_Product
      # ...bb_ProductRegion
      # ...bb_Search_Product
    }
    # ...useLink_bb_Product # TODO This doesn't work because exhibitorId isn't resolved correctly on RegisteredProduct.product.
  }

  fragment ProductsPageBase_Collection on Collection {
    ...ProductFilters_Event
    isProductSortingEnabled
  }

  # fragment ProductsPage on Product {
  #   id
  #   features # The features have to be queried outside of the list row because they are not typically shown.
  #   ...ProductListRow
  #   ...ProductCard
  #   ...useLink_Product # TODO exhibitorId isn't available on RegisteredProduct.product, so we need to make sure it comes from Product at least.
  # }

  # fragment ProductsPage_first on Product {
  #   ...ProductListRow_first
  #   ...ProductListRow
  #   ...ProductCard_first
  #   ...ProductCard
  # }

  # fragment ProductsPage_Event on Event {
  #   ...EventBanner
  # }

  fragment FieldValue on FieldValue {
    ... on TextFieldValue {
      text: value
    }
    ... on IntFieldValue {
      integer: value
    }
    ... on FloatFieldValue {
      number: value
    }
    ... on YesNoFieldValue {
      value
      rawText
    }
    ... on SelectFieldValue {
      text: value
    }
    ... on MultiSelectFieldValue {
      values: value(format: LOCALIZED)
    }
    ... on ChangeoverFieldValue {
      month: value
    }
  }
`);

const PAGE_SIZE = 20;

export default function ProductsPage({
  products: rawData,
  collectionId,
  location,
  locale,
  event,
}: {
  products: FragmentType<typeof fragment>[];
  location: { hash: string; state: any };
  collectionId: string;
  locale: string;
  event: unknown;
}) {
  const { i18n } = useLingui();
  const registeredProducts = useFragment(fragment, rawData);
  const products = registeredProducts.map((p) => ({ ...p, ...p.product }));
  const [state, dispatch] = useLocationSyncedState({
    hash: window.location.hash,
    state: window.history.state,
  });
  const filterConfig = useProductFilters();
  const searched = useSearch(products, state.searchTerm);
  const { bySortIndex, ...otherSortings } = filterConfig.sortings;
  // If sorting is enabled only use the bySortIndex sorting as this is a product-led event.
  const sortingConfig = event.isProductSortingEnabled
    ? { bySortIndex }
    : { ...otherSortings };
  const sortings = Object.entries(sortingConfig).map(([key, sorting]) => ({
    key,
    ...sorting,
  }));
  const activeSorting =
    sortings.find((sorting) => sorting.key === state.sort) || sortings[0];
  const sort = activeSorting.key;
  const index = useIndex(searched, filterConfig);
  const [limit, setLimit] = React.useState(PAGE_SIZE);

  const filters = Object.fromEntries(
    Object.keys(filterConfig.aggregations).map((key) => [key, state[key] || []])
  );

  const { data, pagination } = index.search({ per_page: limit, sort, filters });
  // console.log(sort, filterConfig, data, pagination, timings);

  const { getToggleProps, getFiltersProps, isVisibleOnDesktop } =
    useFiltersDisplay();

  function toggleFilter(aggregation, bucket) {
    const definition = filterConfig.aggregations[aggregation.name];
    const multiSelect = definition?.multiSelect ?? !definition?.conjunction;
    dispatch(filterToggled(aggregation.name, bucket.key, !multiSelect));
    setLimit(PAGE_SIZE);
  }

  const mainColumn = {
    gridColumnStart: [1, isVisibleOnDesktop ? 2 : 1],
    gridColumnEnd: -1,
  };
  return (
    <Box sx={{ backgroundColor: "light" }}>
      {/* <SEO
        page={{ path: location.href }}
        title={i18n._(defineMessage({ message: "Products" }))}
      /> */}
      <Container
        fluid
        sx={{ paddingTop: 3, paddingBottom: 6 }}
        // sx={{ maxWidth: 'container.fluid' }}
      >
        <Stack>
          <ActiveFiltersBar
            aggregations={data.aggregations}
            definitions={filterConfig.aggregations}
            onToggleFilter={toggleFilter}
            onClearFilters={() => dispatch(filtersCleared())}
          />
          <Flex
            gap={2}
            align="baseline"
            justify="space-between"
            sx={{ flexDirection: ["column", "row"] }}
          >
            <ProductsPageTitle
              totalCount={products.length}
              count={pagination.total}
            />
            {/* <DownloadMyBookmarks
                collectionId={collectionId}
                locale={locale}
              /> */}
          </Flex>

          <Grid
            width={316}
            repeat="fill"
            sx={{ alignItems: "center", gridAutoFlow: "dense" }}
          >
            <Sort
              sortings={sortings}
              value={sort}
              onChange={(value) => dispatch(sortChanged(value))}
              sx={{ gridColumn: "-2/-1", textAlign: "right" }}
            />
            <FilterField
              placeholder={i18n._(
                defineMessage({ message: "Enter wine or producer name" })
              )}
              value={state.searchTerm}
              onChange={(value) => {
                setLimit(PAGE_SIZE);
                return dispatch(searchTermChanged(value));
              }}
              variant="large"
              sx={{
                borderColor: "borderSecondary",
                gridColumn: ["1/-1", "2/-2"],
              }}
            />
            <Filters.ToggleDesktop {...getToggleProps()} />
            <Filters.ToggleMobile {...getToggleProps()} />
          </Grid>

          <Grid width={316} repeat="fill" sx={{ alignItems: "flex-start" }}>
            <Filters
              key={state.searchTerm}
              aggregations={data.aggregations}
              definitions={filterConfig.aggregations}
              onToggleFilter={toggleFilter}
              totalCount={products.length}
              count={pagination.totalCount}
              {...getFiltersProps()}
              sx={{
                maxHeight: [null, "100vh"],
                overflowY: [null, "auto"],
                position: [null, "sticky"],
                top: 0,
              }}
            />
            {!pagination.total ? (
              <EmptyState sx={mainColumn} />
            ) : (
              <MainList
                width={300}
                items={data.items}
                group={activeSorting.group}
                groupHeader={activeSorting.groupHeader}
                limit={limit}
                setLimit={setLimit}
                pagination={pagination}
                sx={{ gridAutoFlow: "dense", ...mainColumn }}
              />
            )}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

/** @typedef {{ id: string; productId: string; exhibitorId?: string; producerId?: string; registration: { companyId?: string; } }} Product */
/**
 * @param {{
 * items: Product[];
 * group?: any;
 * groupHeader?: 'exhibitor' | 'producer' | 'room' | 'flightName';
 * pagination: { total: number;  per_page: number; page: number; };
 * limit: number;
 * setLimit: (set: number | ((limit: number) => number)) => void;
 * width?: number;
 * }} props
 * */
function MainList({
  items,
  group,
  groupHeader = "exhibitor",
  pagination,
  limit,
  setLimit,
  width,
  ...rest
}) {
  const link = useLink();
  const hasMore = pagination.total > items?.length;
  const showMore = React.useCallback(
    () => setLimit((/** @type {number} */ limit) => limit + PAGE_SIZE),
    [setLimit]
  );
  const groupedData = groupData({ products: items, group });
  const groupedProductIds = groupedData?.flatMap(([, products]) =>
    products.map(({ productId }) => productId)
  );
  const ungroupedProducts = items.filter(
    (item) => !groupedProductIds?.includes(item.productId)
  );
  return (
    <>
      {groupedData?.length ? (
        <ProductsList
          // hasMore={hasMore}
          // showMore={showMore}
          width={
            // Flights should have a clean break between them
            groupHeader === "flightName" ? "100%" : width
          }
          {...rest}
        >
          {groupedData.map(([key, products]) => (
            <GroupedProductsCard
              key={key}
              exhibitorId={products[0]?.registration?.companyId}
              products={products}
              // groupedBy={products.length === 1 ? 'hidden' : 'exhibitor'}
              groupedBy={groupHeader}
              sx={{
                marginBottom: 4,
                gridColumn: products.length <= 2 ? [null, "span 2"] : "1/-1",
              }}
            />
          ))}
        </ProductsList>
      ) : null}

      <ProductsList
        hasMore={hasMore}
        showMore={showMore}
        width={width}
        {...rest}
      >
        {ungroupedProducts.map((item) => (
          <ProductCard key={item.id} product={item} />
        ))}
      </ProductsList>
    </>
  );
}

function groupData({ products, group }) {
  if (!group) return null;
  const grouped = Object.entries(groupBy(products, group));
  // Filter out empty groups
  const remaining = grouped.filter(([key]) => {
    return !["undefined", "null", ""].includes(key ?? "null");
  });
  return remaining;
}
