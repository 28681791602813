// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/:locale`
  | `/:locale/:slug`
  | `/:locale/bb-admin`
  | `/:locale/c/:collectionId/tasks/:taskId`
  | `/:locale/c/:collectionId/tasks/:taskId/products/:productId`
  | `/:locale/collections/:collectionId`
  | `/:locale/collections/:collectionId/:slug`
  | `/:locale/collections/:collectionId/bb-admin`
  | `/:locale/collections/:collectionId/e/:exhibitorId`
  | `/:locale/collections/:collectionId/exhibitors`
  | `/:locale/collections/:collectionId/exhibitors/:exhibitorId`
  | `/:locale/collections/:collectionId/exhibitors/filters`
  | `/:locale/collections/:collectionId/my/bookmarks`
  | `/:locale/collections/:collectionId/my/lists`
  | `/:locale/collections/:collectionId/my/profile`
  | `/:locale/collections/:collectionId/my/tasting-notes`
  | `/:locale/collections/:collectionId/producers`
  | `/:locale/collections/:collectionId/producers/:producerId`
  | `/:locale/collections/:collectionId/products`
  | `/:locale/collections/:collectionId/products/by-exhibitor/:exhibitorId/:productId`
  | `/:locale/collections/:collectionId/products/filters`
  | `/:locale/collections/:collectionId/validateSigninLink`
  | `/:locale/e/:exhibitorId`
  | `/:locale/exhibitors`
  | `/:locale/exhibitors/:exhibitorId`
  | `/:locale/exhibitors/filters`
  | `/:locale/my/bookmarks`
  | `/:locale/my/lists`
  | `/:locale/my/profile`
  | `/:locale/my/tasting-notes`
  | `/:locale/producers`
  | `/:locale/producers/:producerId`
  | `/:locale/products`
  | `/:locale/products/by-exhibitor/:exhibitorId/:productId`
  | `/:locale/products/filters`
  | `/:locale/workspaces/:workspaceId/collections`
  | `/c/:collectionId/tasks/:taskId/products/:productId`
  | `/collections/:collectionId/*`
  | `/error`
  | `/sc/:shortCodeOrProductId`

export type Params = {
  '/:locale': { locale: string }
  '/:locale/:slug': { locale: string; slug: string }
  '/:locale/bb-admin': { locale: string }
  '/:locale/c/:collectionId/tasks/:taskId': { locale: string; collectionId: string; taskId: string }
  '/:locale/c/:collectionId/tasks/:taskId/products/:productId': { locale: string; collectionId: string; taskId: string; productId: string }
  '/:locale/collections/:collectionId': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/:slug': { locale: string; collectionId: string; slug: string }
  '/:locale/collections/:collectionId/bb-admin': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/e/:exhibitorId': { locale: string; collectionId: string; exhibitorId: string }
  '/:locale/collections/:collectionId/exhibitors': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/exhibitors/:exhibitorId': { locale: string; collectionId: string; exhibitorId: string }
  '/:locale/collections/:collectionId/exhibitors/filters': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/my/bookmarks': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/my/lists': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/my/profile': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/my/tasting-notes': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/producers': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/producers/:producerId': { locale: string; collectionId: string; producerId: string }
  '/:locale/collections/:collectionId/products': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/products/by-exhibitor/:exhibitorId/:productId': { locale: string; collectionId: string; exhibitorId: string; productId: string }
  '/:locale/collections/:collectionId/products/filters': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/validateSigninLink': { locale: string; collectionId: string }
  '/:locale/e/:exhibitorId': { locale: string; exhibitorId: string }
  '/:locale/exhibitors': { locale: string }
  '/:locale/exhibitors/:exhibitorId': { locale: string; exhibitorId: string }
  '/:locale/exhibitors/filters': { locale: string }
  '/:locale/my/bookmarks': { locale: string }
  '/:locale/my/lists': { locale: string }
  '/:locale/my/profile': { locale: string }
  '/:locale/my/tasting-notes': { locale: string }
  '/:locale/producers': { locale: string }
  '/:locale/producers/:producerId': { locale: string; producerId: string }
  '/:locale/products': { locale: string }
  '/:locale/products/by-exhibitor/:exhibitorId/:productId': { locale: string; exhibitorId: string; productId: string }
  '/:locale/products/filters': { locale: string }
  '/:locale/workspaces/:workspaceId/collections': { locale: string; workspaceId: string }
  '/c/:collectionId/tasks/:taskId/products/:productId': { collectionId: string; taskId: string; productId: string }
  '/collections/:collectionId/*': { collectionId: string; '*': string }
  '/sc/:shortCodeOrProductId': { shortCodeOrProductId: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
