import LandingPage from "@bottlebooks/bottlebooks-site-base/src/components/LandingPage/LandingPage.next";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import fetchSanity from "~/fetchSanity";
import getInvalidationPath from "~/getRequestCacheKey";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import SanityPage from "@bottlebooks/bottlebooks-site-cms/src/components/SanityPage";
import { pageQuery } from "@bottlebooks/bottlebooks-site-cms/src/pages/cms-preview/pages/pageQuery";
import { siteQuery } from "@bottlebooks/bottlebooks-site-cms/src/pages/cms-preview/pages/siteQuery";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
});

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader({ params }: LoaderFunctionArgs) {
  const { locale, collectionId: collectionIdSegment } =
    paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query LandingPage($collectionId: ID!, $locale: ContentLocale) {
        collection(collectionId: $collectionId, locale: $locale) {
          ...LandingPage_Event
          site {
            templateSettings {
              name
              value
            }
          }
        }
      }
    `),
    {
      collectionId,
      locale,
    }
  );
  if (!result.collection) throw new Error("No event");
  const collection = result.collection;
  const templateSettings = collection.site?.templateSettings ?? [];
  const projectId = templateSettings.find(
    ({ name }) => name === "projectId"
  )?.value;

  if (!projectId) return { ...collection, isSanity: false, frontpage: null };
  const sanityResult = await fetchSanity(
    siteQuery,
    { collectionId },
    {
      projectId,
      "bottlebooks-use-request-cache": "true",
    }
  );
  const site = sanityResult.data?.allSite?.[0];
  const frontpage: SanitySite = site?.frontpage;
  const sanityPageResult = await fetchSanity(
    pageQuery,
    { collectionId, pageId: frontpage.id },
    {
      projectId,
      ...getInvalidationPath({
        collectionId,
        slug: frontpage.slug.current,
      }),
    }
  );
  const page = sanityPageResult.data?.Page;
  return {
    ...collection,
    frontpage: { ...frontpage, ...page, projectId },
    isSanity: true,
  };
}

type SanitySite = { id: string; title: string; slug: { current: string } };

export default function LandingPageTemplate() {
  // const parsed = paramsSchema.parse(useParams());
  const event = useLoaderData() as LoaderData;
  if (event.isSanity) return <SanityPage {...event.frontpage} />;
  const exhibitors = event?.registrations?.nodes.map((node) => ({
    ...node,
    ...node.profile,
  }));
  return (
    <LandingPage
      // {...pageContext}
      event={event}
      // localeConfig={localeConfig}
      exhibitors={exhibitors}
      producers={[]}
      location={location}
      displayedFeaturedExhibitors={2}
    />
  );
}

const facetsFragment = graphql(/* GraphQL */ `
  fragment LandingPage_Event on Event {
    ...LandingPage
    registrations {
      nodes {
        companyId
        ...LandingPage_FeaturedExhibitors
      }
    }
  }
`);
