/** @jsxImportSource theme-ui */
import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import useExportAllProducts from "@bottlebooks/bottlebooks-site-tastingnotes/src/useExportAllProducts";
import useTastingNoteExport from "@bottlebooks/bottlebooks-site-tastingnotes/src/useTastingNoteExport";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import {
  Dialog,
  DownloadIcon,
  Link,
  Stack,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import "@reach/dialog/styles.css";
import { useFavorites } from "..";

export default function CatalogExportDialog({
  locale,
  onClose,
}: {
  locale: string;
  onClose: () => void;
}) {
  return (
    <Dialog
      onDismiss={onClose}
      aria-label="Catalog export dialog"
      sx={{
        position: "relative",
        width: ["100vw", 600],
        marginTop: [0, "10vh"],
        height: ["100vh", "auto"],
      }}
    >
      <Dialog.Header onDismiss={onClose}>
        <Trans>Download your personal tasting booklet</Trans>
      </Dialog.Header>
      <Dialog.Content>
        <Text sx={{ marginBottom: 3 }}>
          <Trans>
            Your personal tasting booklet contains all the wines you have
            bookmarked, rated, or written tasting notes for.
          </Trans>
        </Text>
        <CatalogExport locale={locale} />
      </Dialog.Content>
    </Dialog>
  );
}

function CatalogExport({ locale }: { locale: string }) {
  const { collectionId } = useCollectionLayout();
  // We don't use the local download anymore
  // const { listsEnabled } = useSiteConfig();
  const { isLoading: isLoadingFavorites, favorites } = useFavorites();
  const { userProfile } = useUserProfile();
  const { exportAsExcel: exportTastingNotes } = useTastingNoteExport();
  const { exportAllProductsAsExcel } = useExportAllProducts();
  if (isLoadingFavorites) return null;
  if (favorites?.length === 0)
    return (
      <Text sx={{ color: "brand.warningRed" }}>
        <Trans>
          You have not added any exhibitors or products to your list. After you
          add items to your list, come back and your personal tasting booklet
          will be ready to download.
        </Trans>
      </Text>
    );

  return (
    <Stack justify="flex-end" sx={{ marginRight: [3, 0] }}>
      <Text>
        <Link
          href={`https://app.bottlebooks.me/pub/next/collections/${collectionId}/tastinglists/users/${userProfile?.uid}?inline=1`}
          target="_blank"
        >
          <DownloadIcon sx={{ marginRight: 2 }} />
          <Trans>Download my tasting lists as PDF</Trans>
        </Link>
      </Text>
      {/* We don't use the local download anymore */}
      {/* <DownloadMyTastingBooklet /> */}
      <Text>
        <Link onClick={exportTastingNotes}>
          <DownloadIcon sx={{ marginRight: 2 }} />
          <Trans>Download tasting booklet as Excel</Trans>
        </Link>
      </Text>
      <Text>
        <Link onClick={exportAllProductsAsExcel}>
          <DownloadIcon sx={{ marginRight: 2 }} />
          <Trans>Download all products as Excel</Trans>
        </Link>
      </Text>
    </Stack>
  );
}
