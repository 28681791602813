import LandingPage from "@bottlebooks/bottlebooks-site-base/src/components/LandingPage/LandingPage.next";
import SanityPage from "@bottlebooks/bottlebooks-site-cms/src/components/SanityPage";
import { pageQuery } from "@bottlebooks/bottlebooks-site-cms/src/pages/cms-preview/pages/pageQuery";
import { siteQuery } from "@bottlebooks/bottlebooks-site-cms/src/pages/cms-preview/pages/siteQuery";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { z } from "zod";
import fetchSanity from "~/fetchSanity";
import getInvalidationPath from "~/getRequestCacheKey";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import getCollectionId from "./_getCollectionId";
import getCustomDomain from "./_getCustomDomain";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader({ params }: LoaderFunctionArgs) {
  const { locale } = paramsSchema.parse(params);
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query LandingPageByCustomDomain(
        $customDomain: String!
        $locale: ContentLocale
      ) {
        collection: _unstable_collectionByCustomDomain(
          experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
          customDomain: $customDomain
          locale: $locale
        ) {
          collectionId
          ...LandingPage_Event
          site {
            templateSettings {
              name
              value
            }
          }
        }
      }
    `),
    {
      locale,
      customDomain: getCustomDomain(),
      // Required to access to cached data.
      collectionId: await getCollectionId({ customDomain: getCustomDomain() }),
    }
  );
  if (!result.collection) throw new Error("No event");
  const collection = result.collection;
  const templateSettings = collection.site?.templateSettings ?? [];
  const projectId = templateSettings.find(
    ({ name }) => name === "projectId"
  )?.value;

  if (!projectId) return { ...collection, isSanity: false, frontpage: null };
  const sanityResult = await fetchSanity(
    siteQuery,
    { collectionId: collection.collectionId },
    {
      projectId,
      "bottlebooks-use-request-cache": "true",
    }
  );
  const site = sanityResult.data?.allSite?.[0];
  const frontpage: SanitySite = site?.frontpage;
  const sanityPageResult = await fetchSanity(
    pageQuery,
    { collectionId: collection.collectionId, pageId: frontpage.id },
    {
      projectId,
      ...getInvalidationPath({
        collectionId: collection.collectionId,
        slug: frontpage.slug.current,
      }),
    }
  );
  const page = sanityPageResult.data?.Page;
  return {
    ...collection,
    frontpage: { ...frontpage, ...page, projectId },
    isSanity: true,
  };
}

type SanitySite = { id: string; title: string; slug: { current: string } };

export default function LandingPageTemplate() {
  // const parsed = paramsSchema.parse(useParams());
  const event = useLoaderData() as LoaderData;
  if (event.isSanity) return <SanityPage {...event.frontpage} />;
  const exhibitors = event?.registrations?.nodes.map((node) => ({
    ...node,
    ...node.profile,
  }));
  return (
    <LandingPage
      // {...pageContext}
      event={event}
      // localeConfig={localeConfig}
      exhibitors={exhibitors}
      producers={[]}
      location={location}
      displayedFeaturedExhibitors={2}
    />
  );
}

const facetsFragment = graphql(/* GraphQL */ `
  fragment LandingPage_Event on Event {
    ...LandingPage
    registrations {
      nodes {
        companyId
        ...LandingPage_FeaturedExhibitors
      }
    }
  }
`);
