import CollectionLayoutProvider from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import BaseLayout from "@bottlebooks/bottlebooks-site-base/src/components/Layout/BaseLayout";
import Layout from "@bottlebooks/bottlebooks-site-base/src/components/Layout/Layout.next";
import SEO from "@bottlebooks/bottlebooks-site-base/src/components/seo.next";
import EventDataProvider from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/EventDataProvider.next";
import { LinkProvider } from "@bottlebooks/bottlebooks-site-base/src/useLink";
import mapToMenuItems from "@bottlebooks/bottlebooks-site-cms/src/@bottlebooks/bottlebooks-site-base/components/Layout/mapToMenuItems";
import { siteQuery } from "@bottlebooks/bottlebooks-site-cms/src/pages/cms-preview/pages/siteQuery";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { LoaderFunctionArgs, Outlet, useParams } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import FirebaseProviders from "~/components/FirebaseProviders";
import { GoogleAnalyticsProvider } from "~/components/GoogleAnalyticsProvider";
import fetchSanity from "~/fetchSanity";
import { graphql, useFragment } from "~/gql";
import { ContentLocale } from "~/gql/graphql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
});

const fragment = graphql(/* GraphQL */ `
  fragment CollectionLayout on Collection {
    collectionId
    ...CollectionLayoutProvider
    name
    ...Layout
    ...SEO
  }
`);

function getQuery({
  locale,
  collectionId,
  // collectionIdSegment includes the site slug
  collectionIdSegment,
}: {
  locale: ContentLocale;
  collectionId: string;
  collectionIdSegment: string;
}) {
  return queryOptions({
    queryKey: ["collection", locale, collectionId],
    queryFn: async () => {
      const result = await graphQLClient.request(
        graphql(/* GraphQL */ `
          query CollectionLayout($collectionId: ID!, $locale: ContentLocale) {
            collection(collectionId: $collectionId, locale: $locale) {
              ...CollectionLayout
              collectionId
              site {
                templateSettings {
                  name
                  value
                }
                primaryColor
                accentColor
              }
            }
          }
        `),
        {
          collectionId,
          locale,
        }
      );
      if (!result.collection) throw new Error("No event");
      const collection = result.collection;
      const templateSettings = collection.site?.templateSettings ?? [];
      const projectId = templateSettings.find(
        ({ name }) => name === "projectId"
      )?.value;
      if (!projectId)
        return {
          ...collection,
          isSanity: false,
          // TODO: implement resolvers to expose this data on GraphQL
          // primaryColor: site.primaryColor,
          // accentColor: site.accentColor,
        };
      const sanityResult = await fetchSanity(
        siteQuery,
        { collectionId },
        {
          projectId,
          "bottlebooks-use-request-cache": "true",
        }
      );
      const site = sanityResult.data?.allSite?.[0];
      if (!site) throw new Error("No CMS site configured for this collection.");
      const menuItems = mapToMenuItems({
        site,
        basePathname: `/${locale}/collections/${collectionIdSegment}`,
      });
      return {
        ...collection,
        logo: site.logo,
        primaryColor: site.primaryColor,
        accentColor: site.accentColor,
        site: {
          ...collection.site,
          menuItems,
          primaryColor: site.primaryColor ?? collection.site?.primaryColor,
          accentColor: site.accentColor ?? collection.site?.accentColor,
        },
        isSanity: true,
        projectId,
      };
    },
    throwOnError: true,
  });
}

function getSiteSearchQuery({
  locale,
  collectionId,
}: {
  locale: ContentLocale;
  collectionId: string;
}) {
  return queryOptions({
    queryKey: ["collection", locale, collectionId, "siteSearch"],
    queryFn: async () => {
      const result = await graphQLClient.request(
        graphql(/* GraphQL */ `
          query CollectionSearch($collectionId: ID!, $locale: ContentLocale) {
            collection(collectionId: $collectionId, locale: $locale) {
              collectionId
              ...useEventData
            }
          }
        `),
        {
          collectionId,
          locale,
        },
        { "bottlebooks-use-request-cache": "true" }
      );
      if (!result.collection) throw new Error("No event");
      return result.collection;
    },
    throwOnError: true,
  });
}

export async function Loader({ params }: LoaderFunctionArgs) {
  const { locale, collectionId: collectionIdSegment } =
    paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  queryClient.prefetchQuery(
    getQuery({ locale, collectionId, collectionIdSegment })
  );
  queryClient.prefetchQuery(getSiteSearchQuery({ locale, collectionId }));
  return null;
}

export default function CollectionLayout() {
  const { locale, collectionId: collectionIdSegment } = paramsSchema.parse(
    useParams()
  );
  const paths = {
    products: "/products",
    exhibitors: "/exhibitors",
    producers: "/producers",
    base: `/${locale}/collections/${collectionIdSegment}`,
  };
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const { data } = useQuery(
    getQuery({ locale, collectionId, collectionIdSegment })
  );
  const collection = useFragment(fragment, data);
  const { data: siteSearchData } = useQuery(
    getSiteSearchQuery({ locale, collectionId })
  );
  if (!collection) return <div>Loading...</div>;
  return (
    <CollectionLayoutProvider data={collection} collectionId={collectionId}>
      <FirebaseProviders>
        <LinkProvider
          value={(context: any) =>
            context || paths ? { ...context, ...paths, locale } : undefined
          }
        >
          <EventDataProvider data={siteSearchData}>
            <GoogleAnalyticsProvider>
              <SEO data={collection} />
              <BaseLayout>
                <Layout
                  locale={locale}
                  sx={{ backgroundColor: "transparent" }}
                  data={collection}
                >
                  <Outlet />
                </Layout>
              </BaseLayout>
            </GoogleAnalyticsProvider>
          </EventDataProvider>
        </LinkProvider>
      </FirebaseProviders>
    </CollectionLayoutProvider>
  );
}
